<script setup lang="ts">
import { computed, defineAsyncComponent, provide, reactive, ref, onMounted } from 'vue'
import router from './router/router'
import { useViewport } from './composables/viewport'
import { useIdentityStore } from './store/identityStore'
import { storeToRefs } from 'pinia'

import packageApp from '../package.json'
import { useButton } from './components/elements/XButton/useButton'
import { useCyberWeekTimer } from './composables/cyberWeekTimer'
// import { useButton } from './components/elements/XButton/useButton'

const appVersion = ref(packageApp.version)

function clearCacheAndReload (): void {
  console.log('Clearing cache and hard reloading...')
  if (caches != null) {
    caches.keys().then((names) => {
      for (const name of names) void caches.delete(name)
    }).catch(err => { console.log(err) })
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.location.reload(true)
}

function checkVersionMismatch (latestVersion: string, currentVersion: string): boolean {
  const latestVersionArray = latestVersion.split(/\./g)
  const currentVersionArray = currentVersion.split(/\./g)
  while (latestVersionArray.length > 0 || currentVersionArray.length > 0) {
    const a = Number(latestVersionArray.shift())
    const b = Number(currentVersionArray.shift())
    if (a === b) continue
    return a > b || isNaN(b)
  }
  return false
}

onMounted(() => {
  fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
    .then(async (response) => response)
    .then(async (meta) => {
      const a = await meta.json()

      const latestVersion = a.version as string
      const currentVersion = appVersion.value
      const shouldForceRefresh = checkVersionMismatch(latestVersion, currentVersion)

      if (shouldForceRefresh) {
        console.log(`New version - ${latestVersion}. Available, need to force refresh`)
        clearCacheAndReload()
      } else {
        console.log(`Already latest version - ${latestVersion}. No refresh required.`)
      }
    }).catch(err => { console.log(err) })
})

const IdentityStore = useIdentityStore()
const { getIdentityShowPopup } = storeToRefs(IdentityStore)

const LayoutLanding = defineAsyncComponent(async () => await import('@/components/landing/LayoutLanding.vue'))
const LayoutPlatfrom = defineAsyncComponent(async () => await import('@/components/platform/LayoutPlatform.vue'))
const isPlatform = computed(() => {
  return router.currentRoute.value.meta.isPlatform
})

// Alerts

const XAlert = defineAsyncComponent(async () => await import('@/components/elements/XAlert/XAlert.vue'))
const AlertParams = reactive({
  visible: false,
  props: {
    content: '',
    type: 'info'
  }
})
interface AlertParamsType {
  content: string
  type: string
}
let XAlertHideTimeout: ReturnType<typeof setTimeout>
function updateAlertParams (params: AlertParamsType): void {
  AlertParams.props.content = params.content
  AlertParams.props.type = params.type
}
function resetAlertParams (): void {
  AlertParams.props.content = ''
  AlertParams.props.type = 'info'
}
function showXAlert (params: AlertParamsType): void {
  updateAlertParams(params)
  AlertParams.visible = true
  XAlertHideTimeout = setTimeout(hideXAlert, 4000)
}
function hideXAlert (): void {
  if (XAlertHideTimeout != null) {
    clearTimeout(XAlertHideTimeout)
  }

  AlertParams.visible = false
  resetAlertParams()
}
provide('showXAlert', showXAlert)

const { ViewportWidth } = useViewport()

const XAlertTransitionName = computed(() => {
  if (ViewportWidth.value < 900) return 'bottom-viewport-slide'
  return 'right-viewport-slide'
})

const XPopup = defineAsyncComponent(async () => await import('@/components/elements/XPopup/XPopup.vue'))
const isXPopupVisible = ref(false)

function showXPopup (): void {
  if (getIdentityShowPopup.value === true) isXPopupVisible.value = true
}
provide('showXPopup', showXPopup)

const CookieBanner = defineAsyncComponent(async () => await import('@/components/common/CookieBanner.vue'))

const showCookiesBanner = ref(false)

onMounted(() => {
  const cookies = localStorage.getItem('_xr_cookies')
  if (cookies == null) showCookiesBanner.value = true

  const showBlackFridayModal = localStorage.getItem('_xr_cwm')

  if (showBlackFridayModal == null) showModal.value = true

  const isReceivedBFGift = localStorage.getItem('_xr_cw_gr') != null
  isGiftReceived.value = isReceivedBFGift
  if (isReceivedBFGift) skeletonName.value = 'skeletonBlackFridayOpened.svg'
})

const XModal = defineAsyncComponent(async () => await import('@/components/elements/XModal/XModal.vue'))
const showModal = ref(false)
function closeModal (): void {
  showModal.value = false
  localStorage.setItem('_xr_cwm', 'false')
}

function showModalBlackFriday (): void {
  if (showTimer.value) showModal.value = true
}
provide('showModalBlackFriday', showModalBlackFriday)

const skeletonName = ref('skeletonBlackFriday.webp')
const skeletonBlackFridayImg = computed(() => {
  return new URL(`/src/theme/images/${skeletonName.value}`, import.meta.url).href
})

const { timeLeft, showTimer } = useCyberWeekTimer()

const BoxOpening = defineAsyncComponent(async () => await import('@/components/common/BoxOpening.vue'))
function BoxOpened (): void {
  skeletonName.value = 'skeletonBlackFridayOpened.svg'
  blurModal.value = false
  localStorage.setItem('_xr_cw_gr', 'true')
  isGiftReceived.value = true
}
const blurModal = ref(false)
function boxOpening (): void {
  blurModal.value = true
}
const modalTitle = computed(() => {
  if (isGiftReceived.value) return 'Cyber Week Offer!'
  if (isReadyForGift.value) return 'Almost there!'

  return "What's inside?"
})
const modalSubTitle = computed(() => {
  if (isGiftReceived.value) {
    return {
      part1: "Missed the Black Friday deal? No worries, we've got you covered!",
      part2: '<b>Get 25% off all Credit Packages and Subscriptions* (including Annual plans!)</b>'
    }
  }
  if (isReadyForGift.value) return 'Open the present now, to enjoy the best offer of the year!'

  return 'Something exciting is coming your way, such things happen only once a year!'
})

const isReadyForGift = computed(() => {
  return showTimer.value
})
const isGiftReceived = ref(false)

const XButton = defineAsyncComponent(async () => await import('@/components/elements/XButton/XButton.vue'))
const ButtonGetGift = reactive(useButton({ onClick: getGift }))
function getGift (): void {
  openPresent.value = true
}
const openPresent = ref(false)
const XLink = defineAsyncComponent(async () => await import('@/components/elements/XLink/XLink.vue'))

</script>

<template>
  <LayoutPlatfrom v-if="isPlatform" />
  <LayoutLanding v-else />

  <Transition
    :name="XAlertTransitionName"
    appear
  >
    <XAlert
      v-if="AlertParams.visible"
      v-bind="AlertParams.props"
      @action:close="hideXAlert"
    />
  </Transition>
  <Transition
    name="bottom-viewport-slide-popup"
    appear
  >
    <XPopup
      v-if="isXPopupVisible"
      @action:close="isXPopupVisible = false"
    />
  </Transition>

  <CookieBanner
    v-if="showCookiesBanner"
    @action-close="showCookiesBanner = false"
  />

  <XModal
    v-if="showModal"
    id="bf-modal"
    :overflow="blurModal"
    @action:close-modal="closeModal"
  >
    <template #header>
      <div class="fl w-f" />
    </template>
    <div
      class="fl c ac modBod "
    >
      <p
        :class="{blurModal:blurModal}"
        class="i-24 i-500 lh-27"
        style="margin-bottom:10px; z-index: 2;"
      >
        {{ modalTitle }}
      </p>
      <div
        class="fl c ac"
        :class="{blurModal:blurModal}"
        style="z-index: 2;"
      >
        <p
          v-if="typeof modalSubTitle === 'object'"
          class="lh-27"
          style="text-align: center; white-space: pre-line"
        >
          {{ modalSubTitle.part1 }}
          <br>
          <span v-html="modalSubTitle.part2" />
        </p>
        <p
          v-else
          class="lh-27"
          style="text-align: center; white-space: pre-line"
        >
          {{ modalSubTitle }}
        </p>
      </div>
      <img
        :src="skeletonBlackFridayImg"
        alt=""
        title="Black Friday deal"
        class="skel"
        style="z-index: 2;"
      >
      <BoxOpening
        v-if="isReadyForGift"
        :is-received="isGiftReceived"
        :open="openPresent"

        style="z-index: 2;"
        @box-opened="BoxOpened"
        @box-opening="boxOpening"
      />
      <div
        :class="{blurModal:blurModal}"
        class="fl c g-20 ac"
        style="text-align: center; z-index: 2;"
      >
        <div
          v-if="isGiftReceived"
          class="fl c ac g-4"
        >
          <p class="lh-27">
            You can purchase as many packages, as you like, but keep in mind, the offer will expire in
          </p>
          <p
            class="i-500 i-24"
            style="font-variant-numeric: tabular-nums;"
          >
            {{ timeLeft }}
          </p>
          <XLink

            :to="{name:'Pricing'}"
            button-like
            button-type="primary"
            @click="closeModal"
          >
            To Store
          </XLink>
        </div>
        <XButton
          v-else-if="isReadyForGift"
          v-bind="ButtonGetGift.props"
          @action:click="ButtonGetGift.onClick"
        >
          Open the box
        </XButton>
        <div
          v-else
          class="fl c ac g-4"
        >
          <p class="lh-27">
            Stay tuned - the reveal will happen in
          </p>
          <p
            class="i-500 i-24"
            style="font-variant-numeric: tabular-nums;"
          >
            {{ timeLeft }}
          </p>
        </div>
      </div>
    </div>
  </XModal>
</template>

<style lang="scss">
#bf-modal {
  .modal-section:nth-child(2) {
    padding: 0 !important;
    @media screen and (max-width: 700px) {
      padding: 16px !important;
    }
  }
  .x-link {
    margin-top: 0px !important;
  }
}

.modBod {
  max-width: 800px;
  width: 800px;
  position: relative;
  .skel{
    height: 360px;
  }
  .x-link{
    margin-top:20px
  }
.blurModal{
  filter: blur(3px);
}

}

@media screen and (max-width:900px) {
  .modBod {
    max-width: 800px;
    width: auto
  }
}
@media screen and (max-width:700px) {
  .modBod {
    .lh-27{
      line-height: 20px;
    }
    p:not(.i-24){
      font-size:14px;
      &.i-14{
        font-size:12px
      }
    }
.x-link{
  margin-top:10px
}
  }
}
@media screen and (max-width:400px) {
  .modBod {
    .skel{
      height:220px;
    }
  }
}

</style>
