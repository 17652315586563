import { reactive } from 'vue'

export function useButton ({
  type = 'primary',
  shape = 'rectangle',
  disabled = false,
  loading = false,
  size = 'small',
  showIcon = false,
  icon = {
    name: '',
    width: 16,
    height: 16,
    title: '',
    alt: ''
  },
  id = '',
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}): useButtonType {
  const props = reactive({ type, shape, disabled, loading, size, showIcon, icon, id })

  return { props, onClick, onMouseEnter, onMouseLeave }
}
interface useButtonType {
  props: useButtonTypeProps
  onClick: Callback
  onMouseEnter: Callback
  onMouseLeave: Callback
}
interface useButtonTypeProps {
  type: string
  shape: string
  disabled: any | boolean
  loading: boolean
  size: string
  showIcon: boolean
  icon: {
    name: string
    width: number
    height: number
    title: string
    alt: string
  }
  id: string

}

type Callback = (...args: any[]) => void
