import { ref, onMounted, onUnmounted } from 'vue'
import type { Ref } from 'vue'

export function useCyberWeekTimer (): { showTimer: Ref<boolean>, timeLeft: Ref<string> } {
  const currentDate = ref(new Date().getTime())
  const startDate = new Date('2024-12-02T00:00:00').getTime()
  const finalDate = new Date('2024-12-07T00:00:00').getTime()
  const showTimer = ref(false)
  const timeLeft = ref('')
  const timer = ref<any>(null)

  function calculateTimeLeft (): string {
    const Time = finalDate - currentDate.value

    const totalHours = Math.floor(Time / 36e5)

    const d = Math.floor(Time / 36e5 / 24)
    const days = d < 10 ? '0' + d : d
    const h = Math.floor(totalHours - (d * 24))

    const hours = h < 10 ? '0' + h : h

    const m = Math.floor((Time % (1000 * 60 * 60)) / (1000 * 60))
    const minutes = m < 10 ? '0' + m : m
    const s = Math.floor((Time % (1000 * 60)) / 1000)
    const seconds = s < 10 ? '0' + s : s
    return `${days}:${hours}:${minutes}:${seconds}`
  }

  function getCurrentTime (): void {
    currentDate.value = new Date().getTime()
    timeLeft.value = calculateTimeLeft()
    if (currentDate.value > finalDate) {
      localStorage.setItem('_xr_cw_a', 'false')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      clearTimeout(timer.value)
      timeLeft.value = '00:00:00:00'
      showTimer.value = false
      window.location.reload()
    }
  }

  function tickTimer (): void {
    getCurrentTime()

    timer.value = setTimeout(tickTimer, 1000)
  }
  onMounted(() => {
    const isActive = localStorage.getItem('_xr_cw_a')

    if (isActive === 'true') {
      if (currentDate.value > finalDate) {
        localStorage.setItem('_xr_cw_a', 'false')
      } else {
        tickTimer()
        showTimer.value = true
      }
    } else if (isActive === 'false') {
      showTimer.value = false
    } else if (isActive == null) {
      if (currentDate.value > startDate) {
        localStorage.setItem('_xr_cw_a', 'true')
        tickTimer()
      }
    }
  })
  onUnmounted(() => {
    timer.value = null
  })
  return { showTimer, timeLeft }
}
